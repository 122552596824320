import { NavLink, Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import { BiLogoFacebook } from 'react-icons/bi';

import { useWebsite } from 'providers/WebsiteProvider';

export default function Menu(props) {
    const { menu, footerMenu } = useWebsite();

    return (
        <div className={'w-full flex flex-col items-center z-40 py-8 bg-stone-700 text-white px-site ' + (props.className || '')}>

            <div className='w-full max-w-boxed flex flex-col items-center gap-8 lg:gap-0 lg:flex-row lg:justify-between'>

                <Link to='/' className='flex flex-col opacity-70'>
                    {/* <Logo white className='hidden lg:flex' />
                    <Logo vertical white className='flex lg:hidden  w-full' /> */}
                    <div className='h-[40px] aspect-[1662/498] bg-no-repeat bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/logo-new-white.png)' }} />

                </Link>

                <div className='flex flex-col gap-8 sm:gap-2 items-center lg:items-end'>

                    <div className='flex flex-row items-center md:divide-x divide-white/10 flex-wrap justify-center' >
                        {menu && menu.map((item) => <MenuItem key={item.ID} data={item} />)}
                        {footerMenu && footerMenu.map((item) => <MenuItem key={item.ID} data={item} highlight />)}
                    </div>

                    <div className='flex flex-col sm:flex-row items-center sm:divide-x divide-white/10'>

                        <div className='text-[10px] text-white/75 text-center sm:pr-3 order-2 sm:order-1'>Siloám Református Missziói Otthon Balatonfüred {new Date().getFullYear()} © Minden jog fenntartva.</div>

                        <div className='flex gap-2 pb-3 sm:pb-0 sm:pl-3 text-2xl sm:text-base order-1 sm:order-2'>
                            <a className='transition-colors hover:text-primary' href={global.facebook} target='_blank'><BiLogoFacebook /></a>
                            <a className='transition-colors hover:text-primary' href={global.instagram} target='_blank'><FaInstagram /></a>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
}

function MenuItem(props) {
    const { getPath } = useWebsite();

    const _d = {
        title: props.data.title,
        object: props.data.object,
        slug: props.data.slug,
        url: props.data.url,
        children: props.data.child_items,
    }

    return (
        <NavLink end to={getPath(props.data)} className={({ isActive }) => ('text-sm sm:text-xs hover:opacity-75 transition-opacity uppercase tracking-wide font-semibold px-3 py-2 sm:py-1 md:py-0 first:sm:pl-0 last:sm:pr-0 ' + (isActive ? ' text-primary' : 'text-white'))}>
            {_d.title}
        </NavLink>
    );
};