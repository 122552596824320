import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { MapRow, GalleryRow, ContactRow } from 'pages/MainPage.js';

export default function ContentPage(props) {
    const [content, setContent] = useState(null);

    let { slug } = useParams();

    useEffect(() => {
        if (slug) {
            const url = global.endpoint + 'wp-json/wp/v2/pages?slug=' + slug + '&_embed';
            axios.get(url, {}).then(function (response) {
                if (response.data)
                    setContent(response.data[0]);
            }).catch(function (error) { console.log(error); });
        }
    }, [slug]);

    return (
        <div className={'w-full flex flex-col  ' + (props.className || '')}>

            {content && <Content data={content} />}

            {slug === 'kapcsolat' && <>
                <ContactRow />
                <MapRow />
            </>}

            {(content && content.acf.photo_gallery && content.acf.photo_gallery.galeria[0].length > 0) && <GalleryRow data={content.acf.photo_gallery.galeria[0]} />}

        </div>
    );
}

function Content(props) {
    const _d = {
        slug: props.data.slug,
        title: props.data.title.rendered,
        date: props.data.date,
        content: props.data.content.rendered,
        image: (props.data._embedded && props.data._embedded['wp:featuredmedia']) && props.data._embedded['wp:featuredmedia'][0].source_url,
    }

    return (
        <div className={'w-full flex flex-col items-center bg-white ' + (props.className || '')}>

            <div className='relative w-full flex flex-col items-center bg-stone-100 px-site bg-cover bg-center' style={{ backgroundImage: 'url(' + _d.image + ')' }}>

                <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-r from-black/70 to-black/20' />

                <div className='w-full max-w-boxed flex flex-col pt-16 md:pt-32 pb-16 z-10'>
                    <div className='text-white text-3xl sm:text-4xl lg:text-6xl font-bold' dangerouslySetInnerHTML={{ __html: _d.title }} />
                </div>

            </div>

            {_d.content && <div className='w-full flex flex-col items-center px-site py-8 xl:py-16'>

                <div className='w-full max-w-boxed flex flex-col'>
                    <div className='leading-relaxed content-text text-stone-600' dangerouslySetInnerHTML={{ __html: _d.content }} />
                </div>

            </div>}

        </div>
    );
}