import { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactForm(props) {
    const [feedback, setFeedback] = useState(false);
    const [sendInProgress, setSendInProgress] = useState(false);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(null);

    const [terms, setTerms] = useState(false);
    const [termsError, setTermsError] = useState(null);

    const send = () => {
        setSendInProgress(true);

        let templateParams = { name: name, phone: phone, email: email, message: message };

        emailjs.send('service_5nwd9w3', 'template_zwr6qms', templateParams).then(
            (response) => {
                setFeedback(true);
                setSendInProgress(false);
            },
            (error) => {
                setSendInProgress(false);
            },
        );
    };

    const validate = () => {
        let valid = true;
        if (name === '') { setNameError('Kérjük, adja meg a nevét!'); valid = false; }
        if (phone === '') { setPhoneError('Kérjük, adja meg telefonszámát!'); valid = false; }
        if (email === '') { setEmailError('Kérjük, adja meg e-mail címét!'); valid = false; }
        if (message === '') { setMessageError('Kérjük, adja meg az üzenet tartalmát!'); valid = false; }
        if (terms === false) { setTermsError('Kérjük, fogadja el az Adatvédelmi tájékoztatóban foglaltakat!'); valid = false; }
        if (valid) send();
    };

    // const resetError = () => {
    //     setNameError(null);
    //     setPhoneError(null);
    //     setEmailError(null);
    //     setMessageError(null);
    // };

    const resetInput = () => {
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
        setTerms(false);
    };

    useEffect(() => { setNameError(null) }, [name]);
    useEffect(() => { setPhoneError(null) }, [phone]);
    useEffect(() => { setEmailError(null) }, [email]);
    useEffect(() => { setMessageError(null) }, [message]);
    useEffect(() => { setTermsError(null); console.log(terms); }, [terms]);

    return (
        <div className={'flex flex-col justify-between gap-6 ' + (props.dark ? 'text-white ' : '') + (props.className || '')}>

            <div className={'flex flex-col gap-4 '}>
                <div className='font-semibold uppercase text-2xl'>Lépjen velünk kapcsolatba</div>
                <div className='text-sm opacity-75'>Az alábbi mezők kitöltésével felveheti velünk a kapcsolatot. Kérjük, ügyeljen rá, hogy minden csillaggal jelölt kötelezően kitöltendő adatot megadott.</div>
            </div>

            <div className='flex flex-col gap-6'>

                <div className='flex flex-col md:flex-row gap-2'>
                    <div className='flex flex-col gap-2 w-full md:w-1/2'>

                        <div className='flex flex-col gap-1'>
                            <input className={props.dark ? 'generic-input' : 'generic-input-light'} placeholder='Név *' value={name} onChange={(e) => setName(e.target.value)} />
                            {nameError && <ErrorMessage message={nameError} />}
                        </div>

                        <div className='flex flex-col gap-1'>
                            <input className={props.dark ? 'generic-input' : 'generic-input-light'} placeholder='Telefonszám *' value={phone} onChange={(e) => setPhone(e.target.value)} />
                            {phoneError && <ErrorMessage message={phoneError} />}
                        </div>

                        <div className='flex flex-col gap-1'>
                            <input className={props.dark ? 'generic-input' : 'generic-input-light'} placeholder='E-mail cím *' value={email} onChange={(e) => setEmail(e.target.value)} />
                            {emailError && <ErrorMessage message={emailError} />}
                        </div>

                    </div>

                    <div className='md:flex-1 h-40 md:h-auto  flex flex-col gap-1'>
                        <textarea className={'w-full h-full ' + (props.dark ? 'generic-input' : 'generic-input-light')} placeholder='Üzenet tartalma *' value={message} onChange={(e) => setMessage(e.target.value)} />
                        {messageError && <ErrorMessage message={messageError} />}
                    </div>

                </div>

                <div className='flex flex-col gap-2'>

                    <div className='flex flex-col gap-2 mb-4'>
                        <div className='flex items-center gap-3'>
                            <input className='accent-primary' type='checkbox' id='terms' name='accepted' checked={terms} onChange={e => setTerms(e.target.checked)} />
                            <div className={'text-[11px] ' + (props.dark ? 'text-white/75' : '')}>Hozzájárulok az üzenetem elküldése során megadott személyes adataimnak az <a href='/adatkezelesi-tajekoztato' className={'font-bold  transition ' + (props.dark ? 'text-white/90 hover:text-white' : 'text-primary/90 hover:text-primary')}>Adatkezelési tájékoztatóban</a> meghatározott célból és ideig történő kezeléséhez.</div>
                        </div>
                        {termsError && <ErrorMessage message={termsError} />}
                    </div>

                    <button disabled={sendInProgress} className='button-dark-hover' onClick={() => { if (feedback) { setFeedback(false); resetInput(); } else { validate(); } }}>{feedback ? 'Vissza' : 'Üzenet küldése'}</button>
                    {feedback && <div className='w-full bg-green-900 text-white text-center p-4 text-xs font-semibold'>Köszönjük, üzenete sikeresen továbbításra került, rövid időn belül felvesszük Önnel a kapcsolatot.</div>}

                </div>

            </div>

        </div>
    );
}

function ErrorMessage(props) {
    return (
        <div className='bg-red-700 text-white text-[10px] font-semibold px-4 py-1.5'>{props.message}</div>);
}