import { useState, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Spin as Hamburger } from 'hamburger-react'
import { FaRegEnvelope } from 'react-icons/fa6';
import { LuPhone } from 'react-icons/lu';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaInstagram } from 'react-icons/fa';
import { BiLogoFacebook } from 'react-icons/bi';

import { useWebsite } from 'providers/WebsiteProvider';

const durationClassName = 'duration-500';

export default function Menu(props) {
    const [scroll, setScroll] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const { filteredMenu, highlightMenu, getPath } = useWebsite();

    useEffect(() => {
        const onScroll = () => { setScroll(window.pageYOffset > 0 ? true : false); };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>

            <div className={'w-full fixed z-40 top-0 flex items-stretch bg-stone-700 shadow-2xl transition-[height,opacity] duration-500 ' + durationClassName + (scroll ? ' h-16 opacity-100 hover:opacity-100 ' : ' h-16 md:h-32 ') + (props.className || '')}>

                <Link to='/' className={'relative h-full flex justify-start transition-all overflow-hidden ' + durationClassName + (scroll ? ' w-36 md:w-36 lg:w-36' : ' w-36 md:w-32')}>

                    <div className={'absolute hidden md:block top-0 left-0 w-32 h-32 p-5 ' + durationClassName + (scroll ? ' translate-y-full opacity-0 ' : '')}>
                        <div className={'w-full h-full bg-no-repeat bg-contain bg-center -mt-1 '} style={{ backgroundImage: 'url(/assets/images/logo-new.png)' }} />
                    </div>

                    <div className={'absolute top-0 left-0 w-36 h-16 p-4 ' + durationClassName + (scroll ? '  opacity-100 ' : ' md:opacity-0 md:-translate-y-full')}>
                        <div className={'w-full h-full bg-no-repeat bg-contain bg-center -mt-1 '} style={{ backgroundImage: 'url(/assets/images/logo-new-white.png)' }} />
                    </div>

                </Link>

                <div className='relative flex-1 flex flex-col'>

                    <div className='absolute left-0 top-0 w-full h-16 bg-white hidden md:flex items-center justify-between z-10'>

                        <div className='flex h-full items-center gap-6 lg:gap-10 xl:gap-12 pl-4 md:pl-8 xl:pl-16' >
                            {filteredMenu && filteredMenu.map((item) => <MenuItem key={item.ID} data={item} />)}
                        </div>

                        <div className={'h-full p-3 transition ' + durationClassName + (scroll ? ' opacity-100' : ' opacity-0')}>
                            {highlightMenu && highlightMenu.map((item) => <Link key={item.ID} to={getPath(item)} className='h-full flex items-center justify-center border hover:bg-primary hover:border-primary px-3 lg:px-4 text-[10px] lg:text-xs tracking-wider font-bold text-stone-600 hover:text-white transition uppercase'>{item.title}</Link>)}
                        </div>

                    </div>

                    <div className='absolute left-0 bottom-0 w-full h-16 flex items-center justify-end md:justify-between'>

                        <div className='hidden md:flex gap-6 xl:gap-10 2xl:gap-12 items-center px-6 md:px-8 xl:px-16'>
                            <ContactItem icon={<FaRegEnvelope />} label='informacio@siloambalatonfured.hu' href='mailto:informacio@siloambalatonfured.hu' />
                            <ContactItem icon={<LuPhone />} label='+36 20 945 4149' href='tel:+36209454149' />
                            <ContactItem className='hidden lg:flex' icon={<HiOutlineLocationMarker />} label='8230 Balatonfüred, Honvéd u. 6' href='https://maps.app.goo.gl/DWFXmuppmDwrJAn27' />
                            <ContactItem icon={<BiLogoFacebook />} label='Facebook' href={global.facebook} />
                            <ContactItem icon={<FaInstagram />} label='Instagram' href={global.instagram} />
                        </div>

                        {highlightMenu && highlightMenu.map((item) => <Link key={item.ID} to={getPath(item)} className='hidden md:flex h-full bg-primary hover:bg-stone-800 transition px-10 text-white font-bold tracking-wide text-xs xl:text-sm items-center justify-center uppercase'>{item.title}</Link>)}

                        <div className='md:hidden h-full aspect-square flex items-center justify-center z-10' >
                            <Hamburger size={32} color={1 > 2 ? '#015359' : '#ffffff'} direction='left' toggled={mobileMenuOpen} toggle={() => setMobileMenuOpen(!mobileMenuOpen)} />
                        </div>

                    </div>

                </div>

            </div>

            <Slider className='flex md:hidden' open={mobileMenuOpen} onSliderClose={() => setMobileMenuOpen(false)} />

        </>
    );
}

function ContactItem(props) {
    return (
        <a className={'flex items-center gap-1.5 xl:gap-2 group ' + (props.className || '')} href={props.href}>

            <div className='text-primary text-base xl:text-lg'>{props.icon}</div>

            <div className='font-light text-xs xl:text-sm text-white/60 group-hover:text-white/100 whitespace-nowrap transition'>{props.label}</div>

        </a>
    );
}

export function Logo(props) {
    if (props.vertical)
        return (
            <div className={'w-32 h-full transition ' + (props.className || '')}>

                <div className='flex flex-col gap-1.5 items-center text-center text-white '>

                    <div className='bg-contain bg-center bg-no-repeat size-8' style={{ backgroundImage: (props.white ? 'url(/assets/images/logo-white.svg)' : 'url(/assets/images/logo.svg)') }} />

                    <div className='flex flex-col tracking-wide'>
                        <div className='text-sm font-bold uppercase'>Siloám</div>
                        <div className='text-[10px] font-light leading-tight mt-px'>Református<br />Missziói Otthon</div>
                        <div className={'text-[10px] font-bold tracking-wider mt-1 ' + (props.white ? '' : 'text-primary')}>Balatonfüred</div>
                    </div>

                </div>

            </div>
        );
    else
        return (
            <div className={'h-full flex items-center transition ' + (props.className || '')}>

                <div className='flex items-center gap-3 text-white'>

                    <div className='bg-contain bg-center bg-no-repeat size-8' style={{ backgroundImage: (props.white ? 'url(/assets/images/logo-white.svg)' : 'url(/assets/images/logo.svg)') }} />

                    <div className='flex flex-col items-start'>
                        <div className='font-light tracking-wider text-[10px] '><span className='font-bold uppercase text-sm md:text-[11px] lg:text-sm'>Siloám</span><span className='inline md:hidden lg:inline'>&nbsp;&nbsp;Református Missziói Otthon</span></div>
                        <div className='hidden md:inline lg:hidden font-light tracking-wider text-[9px]'>Református Missziói Otthon</div>
                        <div className={'font-bold tracking-wider text-[9px] lg:text-[10px] ' + (props.white ? '' : 'text-primary')}>Balatonfüred</div>
                    </div>

                </div>

            </div>
            // <div className={'h-full flex items-center transition ' + (props.className || '')}>

            //     <div className='flex items-center gap-3 text-white'>

            //         <div className='bg-contain bg-center bg-no-repeat size-8' style={{ backgroundImage: (props.white ? 'url(/assets/images/logo-white.svg)' : 'url(/assets/images/logo.svg)') }} />

            //         <div className='flex flex-col items-start'>
            //             <div className='font-light tracking-wide text-sm'><span className='font-bold uppercase'>Siloám</span> Missziói Otthon</div>
            //             <div className={'font-bold tracking-wider text-[10px] ' + (props.white ? '' : 'text-primary')}>Balatonfüred</div>
            //         </div>

            //     </div>

            // </div>
        );
}

function MenuItem(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { getPath } = useWebsite();

    const _d = {
        title: props.data.title,
        object: props.data.object,
        slug: props.data.slug,
        url: props.data.url,
        children: props.data.child_items,
    }

    return (
        <div className='h-full relative group' onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>

            <NavLink end to={getPath(props.data)} className={({ isActive }) => ('flex items-center justify-center text-xs lg:text-sm h-full font-semibold tracking-wider uppercase transition ' + (isActive ? 'group-hover:text-primary' : 'group-hover:text-primary'))}>
                {_d.title}
            </NavLink>

            {_d.children ? <div className='absolute w-full bottom-0 flex justify-center'>
                <MenuBlockItemDropdown className='absolute min-w-full top-0' open={dropdownOpen}>
                    {_d.children.map((item, index) => (<SubMenuItem key={index} data={item} level={2} />))}
                </MenuBlockItemDropdown>
            </div> : null}

        </div>
    );
}

function MenuBlockItemDropdown(props) {
    return (
        <motion.div
            className={'flex-col bg-black/80 shadow-2xl px-6 py-2 backdrop-blur-sm ' + (props.className || '')}
            initial={false}
            animate={props.open ? 'visible' : 'hidden'}
            transition={{ ease: 'easeOut', duration: .2 }}
            variants={{ visible: { opacity: 1, y: 0, display: 'flex' }, hidden: { opacity: 0, y: 20, transitionEnd: { display: 'none' } }, }}>

            {props.children}

        </motion.div>
    );
}

function SubMenuItem(props) {
    const { getPath } = useWebsite();

    return (
        <div className={'flex flex-col items-center ' + (props.className || '')}>

            {props.level > 2 && <div className='w-1 h-1 bg-white rounded-full' />}

            <Link className={'text-xs flex flex-col justify-center text-center tracking-wide whitespace-nowrapp leading-tight transition text-white hover:text-primary py-2 uppercase ' + (props.level < 3 ? 'text-sm font-semibold' : 'text-xs')} to={getPath(props.data)}>
                {props.data.title}
            </Link>

            {props.data.sub ? <div className='flex flex-col'>
                {props.data.sub.map((item, index) => (<SubMenuItem key={index} data={item} collapse={props.collapse} enableCollapse={props.enableCollapse} level={3} />))}
            </div> : null}

        </div>
    );
}

export function Slider(props) {
    let location = useLocation();

    const { menu } = useWebsite();

    useEffect(() => {
        if (props.onSliderClose()) props.onSliderClose();
    }, [location]);

    return (
        <motion.div
            className={'fixed top-0 left-0 w-full h-full z-40 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            transition={{ duration: .2 }}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0, transitionEnd: { display: 'none' } } }}>

            <div className='absolute w-full h-full bg-black/50 ' onClick={() => props.onSliderClose()} />

            <div className='absolute left-0 h-full w-[70%]'>

                <motion.div
                    className='bg-stone-700 text-white shadow-2xl h-full flex flex-col items-center justify-center p-8'
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    transition={{ duration: 0.4 }}
                    variants={{ visible: { translateX: 0, display: 'flex' }, hidden: { translateX: '-100%', transitionEnd: { display: 'none' } } }}>

                    <div className='flex-1 flex flex-col items-center justify-center'>
                        {menu && menu.map((item) => <SliderMenuItem key={item.ID} data={item} />)}
                    </div>

                    <div className='w-full flex flex-col gap-4 items-center'>
                        {/* <Logo vertical /> */}
                        <div className='w-24 aspect-[1400/1479] bg-no-repeat bg-contain bg-center' style={{ backgroundImage: 'url(/assets/images/logo-new.png)' }} />

                        <div className='text-xs opacity-50'>{new Date().getFullYear()} © Minden jog fenntartva.</div>
                    </div>

                </motion.div>

            </div>

        </motion.div>
    );
}

function SliderMenuItem(props) {
    const { getPath } = useWebsite();

    const _d = {
        title: props.data.title,
        children: props.data.child_items,
    }

    return (
        <div className='relative group flex flex-col'>

            <NavLink end to={getPath(props.data)} className={({ isActive }) => ('flex py-4 items-center justify-center h-full font-semibold tracking-wider uppercase transition border-b border-stone-600 group-last:border-b-0  ' + (isActive ? 'group-hover:text-primary' : 'group-hover:text-primary'))}>
                {_d.title}
            </NavLink>

            {_d.children ? <div className='w-full flex flex-col justify-center border-b border-stone-600 py-1'>
                {_d.children.map((item, index) => (<SliderMenuSubItem key={index} data={item} />))}
            </div> : null}

        </div>
    );
}

function SliderMenuSubItem(props) {
    const { getPath } = useWebsite();

    const _d = {
        title: props.data.title,
    }

    return (
        <NavLink end to={getPath(props.data)} className={({ isActive }) => ('flex py-2 text-sm text-primary items-center justify-center h-full font-semibold tracking-wider transition border-b-0 border-stone-600 group-last:border-b-0  ' + (isActive ? 'group-hover:text-primary' : 'group-hover:text-primary'))}>
            {_d.title}
        </NavLink>
    );
}