import './global.js';

import { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from 'components/Header.js';
import Footer from 'components/Footer.js';
import CookieModal from 'components/CookieModal';

import MainPage from 'pages/MainPage.js';
import ContentPage from 'pages/ContentPage.js';

import WebsiteProvider from 'providers/WebsiteProvider.js';

function App() {

  useEffect(() => {
    emailjs.init({ publicKey: 'K0YwjukzHW8GE09AE',});
  }, []);

  return (
    <WebsiteProvider>
      <Router>
        <div className='w-full flex flex-col relative min-h-screen'>

          <Header />

          <div className='flex-1 pt-16'>
            <Routes>
              <Route path='/' element={<MainPage />} />
              <Route path='/:slug' element={<ContentPage />} />
            </Routes>
          </div>

          <Footer />

          {/* <CookieModal /> */}

        </div>
      </Router>
    </WebsiteProvider>
  );
}

export default App;
