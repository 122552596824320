import axios from 'axios';
import { useState, useEffect, createContext, useContext } from 'react';

export const WebsiteContext = createContext(null);
export const useWebsite = () => { return useContext(WebsiteContext); };

export default function WebsiteProvider(props) {
	const [menu, setMenu] = useState(null);
	const [footerMenu, setFooterMenu] = useState(null);

	const [filteredMenu, setFilteredMenu] = useState(null);
	const [highlightMenu, setHighlightMenu] = useState(null);

	useEffect(() => {
		if (menu) {
			let filtered = [];
			let highlight = [];
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].classes.indexOf('highlight') > -1)
					highlight.push(menu[i]);
				else
					filtered.push(menu[i]);
			}
			setFilteredMenu(filtered);
			setHighlightMenu(highlight);
		}
	}, [menu]);

	useEffect(() => {
		const url = global.endpoint + 'wp-json/menus/v1/menus/fomenu';
		axios.get(url, {}).then(function (response) { if (response.data) setMenu(response.data.items); }).catch(function (error) { console.log(error); });
		const url2 = global.endpoint + 'wp-json/menus/v1/menus/lablec';
		axios.get(url2, {}).then(function (response2) { if (response2.data) setFooterMenu(response2.data.items); }).catch(function (error2) { console.log(error2); });
	}, []);

	const getPath = (data) => {
		let path = '/';
		if (data.url.startsWith('#!')) {
			path += data.url.replace('#!', '');
		} else {
			if (data.object === 'page') path += data.slug;
			// if (data.object === 'category') path += 'kategoria/' + data.slug;
		}
		return path;
	};

	const value = {
		menu,
		footerMenu,
		filteredMenu,
		highlightMenu,
		getPath,
	};

	return (
		<WebsiteContext.Provider value={value}>
			{props.children}
		</WebsiteContext.Provider>
	);
}